import React, { useState } from 'react'
import TransitionalModal from "../components/transitional";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { BURL } from '../../../services/base';
// Image
import SmallLoader from '../../widgets/SmallLoader';
import useAPI from '../../../hooks/useAPI';
import { Button, Dialog, DialogContent, DialogContentText, Slide } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import HorizontalLinearStepper from '../components/linearStepper';
import TenantHorizontalLinearStepper from '../components/tenantLinearStepper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function Tenancy() {
    const [loading, setLoading] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [success, setSuccess] = useState(null);
    const [properties, setProperties] = React.useState([])

    const { GET, POST, DELETE } = useAPI();
    const [state, setState] = React.useState({
        opened: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opened } = state;

    const getProperties = React.useCallback(async () => {
        setLoading(true);
        setErrorText(null);
        const response = await GET(`admin/properties`)
        setLoading(false);

        if (response?.status) {
            setProperties(response.data);
        } else {
            setErrorText(response.message ?? 'Request Error');
        }
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        getProperties();
    }, [getProperties])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setShowModal(true);
    };

    // const handleClose = () => {
    //     setOpen(false);
    // };

   
    const deleteProperty = async (propertyId) => {
        console.log('Property_propertyId', propertyId, 'Hello');
        setConfirmLoading(true);
        setErrorText(null);
        console.log('fff', { propertyId });
        
        try {
            const response = await DELETE(`admin/properties/delete/`, { propertyId });
            console.log('redff');
            console.log('Res', response);
            setSuccess(response.message);
            // if (response?.status) {
            //     setState({ ...newState, open: true });
            // } else {
            //     setErrorText(response.message);
            //     console.log('Error', response?.data);
            // }
        } catch (err) {
            setErrorText(err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <div className="md:px-10 sm:px-3 mt-5 md:mt-4 flex justify-end items-center pt-3 bg-white mb-0">
                {/* <TransitionalModal getProperties={getProperties} /> */}
                <button className='px-4 py-2 hover:text-[#0FA958] hover:font-semibold flex items-center gap-1' onClick={handleClickOpen}> <AddHomeWorkIcon className='text-[#6C6C6C]' /> <p className='flex font-light text-[12px] md:text-[14px] items-center py-auto mt-2'>Create Tenant</p>
                </button>
                <Dialog
                open={showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=> setShowModal(false) }
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TenantHorizontalLinearStepper open={showModal} setOpen={setShowModal} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            </div>
            <div className='w-full bg-white'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-12 bg-white w-full mb-10">
                    {/* <div className='pb-10 w-full'> */}
                    <p className='mb-5 font-semibold text-sm text-gray-400'>Tenants</p>
                    {loading && <SmallLoader />}
                    {(errorText && !loading) && errorText}
                    <div className='max-w-md text-center'>{(!errorText && !loading && !properties.length)
                        && 'You have no properties yet. Click CREATE PROPERTY to start setting up a property.'}
                    </div>
                    {errorText && <Button onClick={getProperties}>Try again</Button>}
                    <div className='flex flex-col gap-5 lg:mx-0 justify-center text-center'>
                        There is currently no tenant.
                    </div>
                </div>
            </div>
            {/* <div className="md:px-10 sm:px-3 flex justify-between items-center py-3 border-b-2 bg-white">
                <h1 className="font-bold">  Properties</h1>
            </div> */}

        </>
    )
}   

export default Tenancy