import React, {useState} from 'react'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Link, useNavigate} from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import GroupsIcon from '@mui/icons-material/Groups';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import VillaIcon from '@mui/icons-material/Villa';


const Navbar = () => {
    const [navStatus, setNavStatus] = useState(false); // Initial status
    const navigate = useNavigate();

    const handleClick = () => {
        setNavStatus(!navStatus); // Toggles the status from true to false or vice versa
    };

    const handleLogout = async (values) => {
        localStorage.removeItem("token");
        navigate('/login');
    }

    return (
        <div className="items-center flex justify-end bg-slate-800s">
            {/* <NotificationsNoneIcon /> */}
            {/* <AccountCircleOutlinedIcon className='ml-3'/> */}
            <div className='flex lg:hidden'>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{
                        color: '#aaa',
                        '&:hover': {
                            color: '#aaa'
                        }
                    }}
                    onClick={handleClick}
                >
                    <MenuIcon />
                </IconButton>
            </div>
            <div className='relative cursor-pointer w-[50px] h-[50px]'>
                <img src="/profile-avatar.png" className='rounded-full' alt="" />
            </div>
            <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                autoWidth
                label="Age"
                style={{backgroundColor: 'white'}}
                sx={{
                    height: 40,
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                    "& .MuiSvgIcon-root": {
                        color: "#0FA958",
                    }
                }}
            >
                <MenuItem component={Link} to='/dashboard/settings' style={{fontSize: '14px'}}> My Profile</MenuItem>
                <MenuItem component={Link} to='/dashboard/settings' style={{fontSize: '14px'}}> Messages</MenuItem>
                <MenuItem component={Link} to='/dashboard/settings' style={{fontSize: '14px'}}> My Profile</MenuItem>
                <MenuItem component={IconButton} style={{fontSize: '14px'}} onClick={handleLogout}>Logout</MenuItem>
            </Select>

            {navStatus && (
                <div className='absolute top-0 left-0 bg-black/50 w-full min-h-screen h-full flex cursor-pointer' onClick={handleClick}>
                    <aside className="w-60 px-6 overflow-y-auto h-full bg-white md:block flex-shrink-0 fixed py-2">
                        <div class="py-4 text-gray-500 dark:text-gray-400 flex flex-col gap-4">
                            <Link to='/dashboard/home' className='font-bold text-[#0FA958] text-center'>Austel</Link>
                            <List className='flex flex-col gap-3'>
                                <ListItem button component={Link} to='/dashboard/home' style={{backgroundColor: 'inherit', borderLeft: 'none'}}>
                                    <Tooltip title='Overview' placement='right' arrow>
                                        <ListItemIcon>
                                            <DashboardTwoToneIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Dashboard' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to='/dashboard/properties'
                                    style={{backgroundColor: 'inherit', borderLeft: 'none'}}
                                >
                                    <Tooltip title="Properties" placement='right' arrow>
                                        <ListItemIcon>
                                            <VillaIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Properties' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to='/dashboard/tenants'
                                    style={{backgroundColor: 'inherit', borderLeft: 'none'}}
                                >
                                    <Tooltip title='Tenants' placement='right' arrow>
                                        <ListItemIcon>
                                            <GroupsIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Tenancy' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to='/dashboard/payments'
                                    style={{backgroundColor: 'inherit', borderLeft: 'none'}}
                                >
                                    <Tooltip title="Payment" placement='right' arrow>
                                        <ListItemIcon>
                                            <CreditCardIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Payment' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to='/dashboard/message'
                                    style={{backgroundColor: 'inherit', borderLeft: 'none'}}
                                >
                                    <Tooltip title="Messages" placement="right" arrow>
                                        <ListItemIcon>
                                            <ForumTwoToneIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Messages' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to="/dashboard/letters"
                                >
                                    <Tooltip title="Letters" placement="right" arrow>
                                        <ListItemIcon>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Letters' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to='/dashboard/settings'
                                >
                                    <Tooltip title="Settings" placement="right" arrow>
                                        <ListItemIcon>
                                            <SettingsTwoToneIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary='Settings' primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>

                                <ListItem button component={Link} to="/dashboard/logs"
                                >
                                    <Tooltip title="Log" placement="right" arrow>
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary="Log" primaryTypographyProps={{style: {fontSize: '14px'}}} />
                                </ListItem>
                            </List>
                        </div>
                    </aside>
                </div>
            )}
        </div >
    )
}

export default Navbar