import React, { useState } from 'react'
import TransitionalModal from "../components/transitional";
import { BURL } from '../../../services/base';
// Image
import SmallLoader from '../../widgets/SmallLoader';
import useAPI from '../../../hooks/useAPI';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

function Properties() {
    const [loading, setLoading] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [success, setSuccess] = useState(null);
    const [properties, setProperties] = React.useState([])

    const { GET, POST, DELETE, deleteRequest } = useAPI();
    const [state, setState] = React.useState({
        opened: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opened } = state;

    const getProperties = React.useCallback(async () => {
        setLoading(true);
        setErrorText(null);
        const response = await GET(`admin/properties`)
        setLoading(false);

        if (response?.status) {
            setProperties(response.data);
        } else {
            setErrorText(response.message ?? 'Request Error');
        }
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        getProperties();
    }, [getProperties])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
   
    const deleteProperty = async (propertyId) => {
        const url = `${BURL}/admin/properties/delete/`
        const token = localStorage.getItem('token');
        setConfirmLoading(true);
        setErrorText(null);
        console.log('fff', { propertyId });
        try {
            const body = { propertyId };
            const response = await deleteRequest(url, body, token);
            setSuccess(response.message);
            getProperties();
          } catch (error) {
            setErrorText(`An error occurred, ${error}`);
          }
    };


    return (
        <>
            <div className="md:px-10 sm:px-3 mt-5 md:mt-4 flex justify-end items-center pt-3 bg-white mb-0">
                <TransitionalModal getProperties={getProperties} />
            </div>
            <div className='w-full bg-white'>
                {/* Property section */}
                <div className="px-[1.22rem] md:px-12 bg-white w-full mb-10">
                    {/* <div className='pb-10 w-full'> */}
                    <p className='mb-5 font-semibold text-sm text-gray-400'>Properties</p>
                    {loading && <SmallLoader />}
                    {(errorText && !loading) && errorText}
                    <div className='max-w-md text-center'>{(!errorText && !loading && !properties.length)
                        && 'You have no properties yet. Click CREATE PROPERTY to start setting up a property.'}
                    </div>
                    {errorText && <Button onClick={getProperties}>Try again</Button>}
                    <div className='flex flex-col gap-5 lg:mx-0'>
                        {!loading && properties.map(role =>
                            <div className='flex w-full bg-[#F1F1F1] px-3 md:px-5 py-3 rounded gap-4 md:gap-[7rem]'>
                                <div className='flex md:gap-6 w-1/5'>
                                    <img src="/Frame469.png" className='hidden md:flex' alt="" />
                                    <div className='flex flex-col gap-0 lg:gap-1 justify-center'>
                                        <p className='text-[13px] xl:text-[16px] font-[600] text-[#0FA958]'>{role.property.name},</p>
                                        <p className='text-[7px] xl:text-[12px] text-[#6C6C6C] text-wrap'>{role.property.address.address}</p>
                                    </div>
                                </div>

                                <div className='flex w-[100%] justify-between w-3/5'>
                                    <div className='flex flex-col gap-0 text-[#6C6C6C] justify-center'>
                                        <p className='text-[12px] lg:text-[18px] font-[600]'>{role.property.units.length} Units</p>
                                        {/* <p className='text-[7px] lg:text-[12px]'>{role.property.type}</p> */}
                                    </div>

                                    <div className='my_property hidden md:flex justify-center items-center h-[auto]'>
                                        <Link to={`/dashboard/properties/${role.property.id}`}
                                            className='view_property bg-[#0FA958]/[.20] text-[#6C6C6C] rounded-[5px] md:px-3 lg:px-2 py-2 md-text-[8px] lg-text-[14px]' style={{ fontSize: '12px' }}>View Property</Link>
                                    </div>
                                    {/* <p className='text-[11px] lg:text-[14px] flex gap-2 justify-center items-center'>
                                            <img src="/greenDot.png" className='w-[12px] h-[12px]' alt="" />
                                            <span className='w-[64px] font-light'>Occupied</span>
                                            <span className='ml-3 text-[14px] font-light'>0</span>
                                        </p>
                                        <p className='text-[11px] lg:text-[14px] flex gap-2 items-center'>
                                            <img src="/redDot.png" className='w-[12px] h-[12px]' alt="" />
                                            <span className='w-[64px] font-light'>Empty</span>
                                            <span className='ml-3 text-[14px] font-light'>0</span>
                                        </p> */}

                                    <div className='my_property hidden md:flex md:gap-5 justify-center items-center'>
                                        {/* <EditIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} /> */}
                                        <ArchiveIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} />
                                        <DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} onClick={() => deleteProperty(role.property.id)} />
                                        {/* {confirmLoading ? <SmallLoader /> :  <DeleteIcon className='flex items-center cursor-pointer text-[#6C6C6C]' sx={{ fontSize: '14' }} onClick={() => deleteProperty(role.property.id)} />} */}
                                    </div>
                                    <div className='options flex md:hidden md:gap-4 justify-center items-center'>
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon className='text-[#6C6C6C]' />
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem sx={{ fontSize: 12 }} onClick={handleClose}>View</MenuItem>
                                            <MenuItem sx={{ fontSize: 12 }} onClick={handleClose}>Archive</MenuItem>
                                            {/* <MenuItem sx={{ fontSize: 12 }} onClick={() => deleteProperty(id, { vertical: 'bottom', horizontal: 'right' })}>Delete</MenuItem> */}
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="md:px-10 sm:px-3 flex justify-between items-center py-3 border-b-2 bg-white">
                <h1 className="font-bold">  Properties</h1>
            </div> */}

        </>
    )
}   

export default Properties  