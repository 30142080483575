import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import UnitAccordion from '../components/unitAccordion';
import useAPI from '../../../hooks/useAPI';
import SmallLoader from '../../widgets/SmallLoader'
import { states } from '../../../services/base'
import { useNavigate } from 'react-router';
import { pptInitialValues, pptTypes, tenantInitialValues } from '../../../services/utils';
import { BURL } from '../../../services/base';
import axios from 'axios';
import { string } from 'yup';

const steps = ['Tenant Details'];

export default function TenantHorizontalLinearStepper({ getProperties, open, setOpen }) {

    // Formik form
    const { values, handleChange, resetForm } = useFormik({ initialValues: tenantInitialValues })
    // End of formik

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [properties, setProperties] = React.useState([]);
    const [rooms, setRooms] = React.useState([]);

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const nextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }


    const handleNext = () => {
        // create property before going to units setup
        if (activeStep + 1 === 1) {
            // createProperty();
            // setup units before showing `setup complete`
        } else if (activeStep + 1 === 2) {
            saveUnit();
        } else {
            nextStep()
        }
        // if(ac)
    };

    const { POST, GET } = useAPI()
    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [errorText, setErrorText] = React.useState(null);
    const [property, setProperty] = React.useState(null);
    const [units, setUnits] = React.useState([])
    const [propertyId, setPropertyId] = React.useState(string);
    const [unitId, setUnitId] = React.useState(string);

    const getAllProperties = React.useCallback(async () => {
        setLoading(true);
        setErrorText(null);
        const response = await GET(`admin/properties`)
        setLoading(false);

        if (response?.status) {
            setProperties(response.data);
        } else {
            setErrorText(response.message ?? 'Request Error');
        }
        // eslint-disable-next-line
    }, []);

    const handlePropertyChange = async (e) => {
        setPropertyId(e.target.value);
        getUnitByPropertyId(e.target.value);
    }

    const handleUnitChange = async (e) => {
        setUnitId(e.target.value);
        getRoomByUnit(e.target.value);
    }

    React.useEffect(() => {
        getAllProperties();
    }, []);

    React.useEffect(() => {
        getAllProperties();
    }, []);

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setOpen(false);
        setDone(false);
    };

    // States in nigeria
    const statesItemList = states.map(stateItem => {
        return (
            <MenuItem value={stateItem}>{stateItem}</MenuItem>
        )
    })
    // End of states in  Nigeria

    // Property
    const propertyList = properties.map(property => {
        return (
            <MenuItem value={property.propertyId}>{property.property.name}</MenuItem>
        )
    })
    // End of property  

    // Unit
    const unitList = units.map(unit => {
        return (
            <MenuItem value={unit.id}>{unit.name}</MenuItem>
        )
    })
    // End of unit  

    // Room
    const roomList = rooms.map(room => {
        return (
            <MenuItem value={room.id}>{room.tag}</MenuItem>
        )
    })
    // End of room  

    // Property type
    const typeList = pptTypes.map(propertyItem => {
        return (
            <MenuItem value={propertyItem}>{propertyItem}</MenuItem>

        )
    })
    // End of property type  

    // handling multiple units

    React.useEffect(() => {
        if (activeStep === 1 && !units.length) addUnit();
        // eslint-disable-next-line
    }, [activeStep, units.length])

    const addUnit = () => {
        setUnits(e => [...e, {
            id: e.length + 1,
            name: 'New Unit ' + (e.length + 1),
            noOfRooms: 1, saved: false,
            rooms: [{ tag: 'Room 1' }],
        }])
    }

    const saveUnit = async () => {
        // get unsaved units
        const filtered = units.filter(each => !each.saved)
        // loop through each unsaved units and save
        try {
            for (let i = 0; i < filtered.length; i++) {
                const eachUnit = filtered[i];
                updateUnit(eachUnit, { loading: true, error: false })
                const response = await POST('admin/properties/units/new', { ...eachUnit, propertyId: property.id });
                if (response?.status) {
                    updateUnit(eachUnit, { error: false, saved: true, loading: false })
                } else {
                    updateUnit(eachUnit, { error: response?.message, loading: false })
                }
            }
        } catch (error) {
            setErrorText(error);
        }
    }

    const updateUnit = (unit, update) => {
        setUnits(e => e.map((eachUnit) => eachUnit.id === unit.id ? ({ ...eachUnit, ...update }) : eachUnit));
    }

    // when the dialog close
    React.useEffect(() => {
        if (!open && done) {
            setOpen(false);
            setDone(false);
            setActiveStep(0);
        }
        // refetch properties if the method is been passed down to this component
        getProperties && getProperties();
        // eslint-disable-next-line
    }, [open, done])


    // check if setup is completed
    React.useEffect(() => {
        // get unsaved units
        const unsavedUnits = units.filter(each => !each.saved)
        // unit setup step is skipped or (we have units, no unsaved unit and property has been set)
        if ((!unsavedUnits.length && property && units.length) || isStepSkipped(1)) {
            resetForm();
            setDone(true)
            setUnits([]);
            setActiveStep(2);
            setProperty(null);
            setSkipped(new Set())
        }
        // eslint-disable-next-line
    }, [units, property, activeStep]);

    const getUnitByPropertyId = React.useCallback(async (propertyId) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        setErrorText(null);
        const response = await axios.get(`${BURL}/admin/properties/${propertyId}/units`, {
            params: {
                withRooms: false,
                withProperty: false
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        // const response = await GET(`admin/properties`)
        setLoading(false);
        if (response?.status) {
            const res = response.data.data
            setUnits(res);
        } else {
            // setErrorText(response.message ?? 'Request Error');
        }
        // eslint-disable-next-line
    }, []);

    const getRoomByUnit = React.useCallback(async (id) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        setErrorText(null);
        const response = await axios.get(`${BURL}/admin/properties/units/${id}/rooms`, {
            params: {
                withProperty: false,
                withUnit: true,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        // const response = await GET(`admin/properties`)
        setLoading(false);

        if (response?.status) {
            const res = response.data.data
            setRooms(res);
        } else {
            setErrorText(response.message ?? 'Request Error');
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        getUnitByPropertyId();
        getRoomByUnit();
    }, []);

    
    const addTenants = async () => {
        setLoading(true);
        setErrorText(null);
        values.propertyId = propertyId;
        values.stayDuration = values.stayDuration.toString();
        
        console.log('valurs', values);
        const response = await POST(`admin/properties/new`, values);
        setLoading(false);
        if (response?.status) {
            console.log('responseRooms', response);
            // !property && setProperty(response.data);
            // nextStep()
        } else {
            setErrorText(response.message);
        }
    }

    const loader = <SmallLoader />
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //         <Typography variant="caption">Optional</Typography>
                    //     );
                    // }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        <div className="max-w-md p-5 text-center">
                            Tenant setup has been completed. Click proceed to view tenants or click out to close this dialog.
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Proceed</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div sx={{ mt: 2, mb: 1 }}>
                        {
                            activeStep === 0 &&
                            <div>
                                <div className="my-10">
                                    <h1 className='text-3xl font-bold text-black'> Tenant Details</h1>
                                    <p className="text-[0.8em] mt-2">Enter the tenant information.</p>
                                    {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}
                                </div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className="w-100 gap-4 grid">
                                        <div className="w-100 gap-3 grid grid-cols-[58%,40%]">
                                            <TextField id="standard-basic" label="First Name" variant="outlined" name='firstname' value={values.firstname} onChange={handleChange} />
                                            <TextField id="standard-basic" label="Last Name" variant="outlined" name='lastname' value={values.lastname} onChange={handleChange} />
                                        </div>
                                        <TextField id="standard-basic" label="Email" variant="outlined" name='email' value={values.email} onChange={handleChange} />
                                        <div className="w-100 gap-3 grid grid-cols-[58%,40%]">
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Property</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='propertyId'
                                                        value={propertyId}
                                                        onChange={handlePropertyChange}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                        }}
                                                    >
                                                        {propertyList}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='unitId'
                                                        value={unitId}
                                                        onChange={handleUnitChange}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                        }}
                                                    >
                                                        {unitList}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Room</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='roomId'
                                                        value={values.roomId}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                        }}
                                                    >
                                                        {roomList}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        <TextField type='number' id="standard-basic" label="Stay Duration" variant="outlined" name='stayDuration' value={values.stayDuration} onChange={handleChange} />
                                    </div>
                                </Box>
                            </div>
                        }
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={addTenants} disabled={loading} className='flex gap-1 items-center'>
                            {activeStep === steps.length == 0 ? 'Finish' : 'Next'}

                            {loading && loader}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }
        </Box >
    );
}
