import { Alert, Checkbox, Snackbar, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import irrImg from '../../assets/auth-bg.svg'
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { useFormik } from 'formik'
import { resetPasswordSchema, signInSchema } from '../../schemas/validation.schema'
import useAPI from '../../hooks/useAPI'
import { TouchAppRounded } from '@mui/icons-material'

export default function ResetPassword() {

  const navigate = useNavigate();
  // Page title
  useEffect(() => {
    document.title = "Austel | Reset Password"
  })

  const { POST } = useAPI();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [success, setSuccess] = useState(null);

  const [state, setState] = React.useState({
     open: false,
     vertical: 'top',
     horizontal: 'center',
 });
 const { vertical, horizontal, open } = state;
  const handleResetPwrd = async (values) => {
    setLoading(true);
    setErrorText(null);
    const response = await POST('password-reset-request', values);
    setLoading(false);
    if (response?.status) {
      setSuccess(response.message);
    } else {
      setErrorText(response.message);
    }
  }

  const handleSnackbarClose = (
    event,
    reason
) => {
    if (reason === 'clickaway') {
        return;
    }
    setState({ ...state, open: false });
};

  // Beginning of formik form
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: handleResetPwrd
  });
  // End of formik form

  return (
    <div className='flex h-screen p-2 bg-house-pattern bg-tr-white justify-center bg-no-repeat bg-center bg-cover bg-blend-color-burn items-center'>
      <img draggable="false" className='fixed top-auto' width={615} src={irrImg} alt="bg" />
      <div className='z-10 px-10 pt-10 pb-20 m-auto w-[450px] shadow-xl bg-white text-center'>

        <h3 className='text-3xl font-bold text-austel-green'>Reset Password</h3>

        <span className='text-austel-green text-sm block mb-4 font-semibold'></span>
        <form onSubmit={handleSubmit}>
          {errorText && <div className='text-sm text-red-500 py-2'>{errorText}</div>}

          <CustomTextField label="Email" name="email" value={values.email} onChange={handleChange} />
          {TouchAppRounded.email && errors.email && <div className='text-red-600 text-xs text-left'>{errors.email}</div>}
          <button className='text-white bg-austel-green mt-8 py-3 px-20 my-2 block mx-auto text-sm' type='submit'>{loading ? 'Loading...' : "Reset Password"}</button>
          <span className="text-sm mt-2 text-gray-400">Remember password? <Link to="/login" className="text-austel-green">Login</Link></span>
        </form>

      </div>
      {success && <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {success}
                </Alert>
            </Snackbar>}
    </div>
  )
}

const CustomTextField = (props) => {
  const styles = {
    label: {
      fontSize: 13
    },
    inputP: {
      fontSize: 13,
    }
  }
  return <>
    <TextField
      required
      variant='standard'
      size='small'
      fullWidth
      InputLabelProps={{ style: styles.label }}
      InputProps={{
        style: styles.inputP
      }}
      {...props}
    />
  </>
}
